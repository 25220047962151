import React, { useState, useCallback, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import ArrowDown from "../ArrowDown";
import SectionTitle from "../SectionTitle";
// mock data
import { mockTestimoni } from "../../mock/mockData";
import { useTranslation } from "react-i18next";

const autoplayOptions = {
  stopOnInteraction: false,
  // stopOnMouseEnter: true,
  // playOnInit: false,
  delay: 10000, // 3 second
};

const HomeTestimoni = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: "center",
      containScroll: "trimSnaps",
      loop: true,
    },
    [Autoplay(autoplayOptions)]
  );

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    emblaApi.plugins().autoplay?.reset();
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const { t } = useTranslation();

  return (
    <div className="container mx-auto max-w-screen-xl px-5 lg:px-10 my-28">
      <SectionTitle>{t("home.section4.testimonial.title")}</SectionTitle>
      <div className="relative">
        <div ref={emblaRef} className="overflow-hidden lg:mx-10">
          <div className="flex">
            {mockTestimoni.map(({ name, testimoni, desc }, i) => {
              return (
                <div
                  key={i}
                  className="flex items-center justify-center flex-shrink-0 w-full lg:w-1/3 h-80 lg:px-4"
                >
                  <div className="flex flex-col gap-4">
                    <h1 className="text-md lg:text-lg text-center italic">
                      {t(`home.section4.testimonials.${i + 1}.body`)}
                    </h1>
                    <div>
                      <p className="text-sm lg:text-md text-center font-bold">
                        {t(`home.section4.testimonials.${i + 1}.name`)}
                      </p>
                      <p className="text-sm lg:text-md text-center">
                        {t(`home.section4.testimonials.${i + 1}.title`)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="absolute top-1/2 w-full flex justify-center gap-4">
          <div className="w-full flex justify-between lg:mx-3">
            <button onClick={() => emblaApi?.scrollPrev()}>
              <span className="sr-only">Previous</span>
              <ArrowDown className="w-4 rotate-90 text-gray-400" />
            </button>
            <button onClick={() => emblaApi?.scrollNext()}>
              <span className="sr-only">Next</span>
              <ArrowDown className="w-4 -rotate-90 text-gray-400" />
            </button>
          </div>
        </div>
        <div className="absolute bottom-5 w-full flex justify-center gap-4">
          {mockTestimoni.map((_, index) => {
            return (
              <button
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === selectedIndex ? "bg-black" : "bg-gray-300"
                }`}
                onClick={() => {
                  emblaApi?.scrollTo(index);
                  emblaApi?.plugins().autoplay?.reset();
                }}
              >
                <span className="sr-only">{`Select slide ${index + 1}`}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeTestimoni;
