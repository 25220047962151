import { create } from "zustand";
import { Api } from "../../lib/api/axios";

const useHomeSlider = create ((set, get) => ({
  data:[],
  totalData:0,
  getHomeSlider: async () =>{
    try{
      const res = await Api.get(`/cms/home-sliders?is_display=true`);
      set(() => ({
        data: res.data?.docs,
        totalData: res.data?.totalDocs,
      }));
      return res.data;
    }catch(error){
      console.error('Error fetching home sliders:', error);
      return error;
    }
  },
  getData: async (searchTerm = '', page = 1, perPage = 10, sort = '', sortType = '') =>{
    try {
      const res = await Api.get(
        `/cms/home-sliders?searchTerm=${searchTerm}&page=${page}&limit=${perPage}&sort=${sort}&sortType=${sortType}`
      );
      set(() => ({
        data: res.data?.docs,
        totalData: res.data?.totalDocs,
      }));
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  deleteData: async (id, controller) => {
    try{
      const {status} = await Api.delete(`/cms/home-sliders/${id}`);
      await get().getData(controller?.search, controller?.page, controller?.perPage);
      return status;
    }
    catch(error){
      return error.response?.data;
    }
  },
  updateData: async (id, payload, controller) => {
    try{
      payload = {...payload, updated_at: new Date()};
      const {status} = await Api.patch(`/cms/home-sliders/${id}`, payload);
      await get().getData(controller?.search, controller?.page, controller?.perPage);
      return status;
    }
    catch(error){
      return error.response?.data;
    }
  },
  createData: async (payload, controller) => {
    try{
      payload = {...payload, updated_at: new Date()};
      const {status} = await Api.post(`/cms/home-sliders`, payload);
      await get().getData(controller?.search, controller?.page, controller?.perPage); 
      return status;
    }
    catch(error){
      return error.response?.data;
    }
  },
  upSequence: async (id, controller)=> {
    try{
      const response = await Api.post(`/cms/home-sliders-seq-up/${id}`);
      await get().getData(controller?.search, controller?.page, controller?.perPage);
      return response.data;
    }
    catch( error){
      console.log("error:", error);
      return error.response?.data;
    }
  },
  downSequence: async (id, controller)=> {
    try{
      const response = await Api.post(`/cms/home-sliders-seq-down/${id}`);
      await get().getData(controller?.search, controller?.page, controller?.perPage);
      return response.data;
    }
    catch( error){
      console.log("error:", error);
      return error.response?.data;
    }
  }
}));


export default useHomeSlider;