import { memo, useState, useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import Heading from "../typography/Heading";
import ButtonLogout from "../button/Button.logout";
import Paragraph from "../typography/Paragraph";
import { MenuAdmin } from "./listMenu/new-admin";
import { HiChevronLeft, HiOutlineMenu } from "react-icons/hi";
import useAuth from "../../../hooks/zustand/useAuth";
import usePersonalInformation from "../../../hooks/zustand/usePersonalInformation";
import { Outlet, useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { Link } from "react-router-dom";
import Spacing from "../spacing/Spacing";
import { Suspense } from "react";
import Loading from "../Loading";
import useAdminAuth from "../../../hooks/zustand/admin/useAdminAuth";
import { convertTime } from "../../../lib/table-date.formatter";

const themes = {
  dark: {
    sidebar: {
      backgroundColor: "#202123",
      color: "white",
    },
    menu: {
      menuContent: "#202123",
      icon: "#fffff",
      hover: {
        backgroundColor: "#343541",
        color: "#fffff",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const DashboardAdminLayout = memo(({ children }) => {
  const userInfo = usePersonalInformation(
    (state) => ({ user: state.user, reset: state.reset }),
    shallow
  );
  // const [t] = useTranslation("navbar");
  const auth = useAuth();
  const navigate = useNavigate();
  const admin = useAdminAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [hasImage] = useState(false);
  const [theme] = useState("dark");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    // Update the currentDateTime every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    window.history.forward();

    // const handleBeforeUnload = ( event ) => {
    //   event.preventDefault();
    //   console.log("beforeUnload", event);
    // };

    // window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
      //window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []); // Empty dependency array ensures the effect runs only once

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const handleLogout = () => {
    userInfo.reset();
    auth.logout();
    admin.logout();
    window.location.href = "/sign-in-admin";
    //navigate("/sign-in-admin");
  };
  const currYear = new Date().getFullYear();
  const projectTitle = process.env.REACT_APP_PROJECT_TITLE ?? "PRODUKEWANGAN";
  return (
    <div className="w-full h-full flex">
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        breakPoint="md"
        backgroundColor="#202123"
        rootStyles={{
          color: themes[theme].sidebar.color,
          border: 0,
        }}
        className="min-h-screen !h-screen lg:!sticky top-0"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
          }}
        >
          <div className="relative w-full">
            <img
              style={{
                margin: "12px 24px",
              }}
              src={process.env.REACT_APP_LOGO_PATH}
              //src="/images/Produk-Kewangan-Main-Logo.png"
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className={`w-24 h-8 object-contain object-center duration-300 whitespace-nowrap hidden ${
                collapsed ? "md:hidden" : "md:block"
              }`}
            />
            <span
              className={`absolute top-3 hidden lg:block right-3 text-white/60 cursor-pointer text-2xl ${
                collapsed ? "rotate-180 right-7" : "rotate-0"
              } duration-300`}
              onClick={() => setCollapsed(!collapsed)}
            >
              <HiChevronLeft />
            </span>
          </div>
          <div style={{ marginBottom: "24px", marginTop: "16px" }} />
          <div style={{ flex: 1, marginBottom: "32px" }}>
            {MenuAdmin().map((menu) => (
              <div key={menu.key}>
                <div style={{ padding: "0 24px", marginBottom: "8px" }}>
                  <Paragraph
                    style={{
                      opacity: collapsed ? 0 : 0.7,
                      letterSpacing: "0.5px",
                    }}
                    className="font-thin capitalize whitespace-nowrap duration-300"
                  >
                    {menu.menu}
                  </Paragraph>
                </div>
                <Menu menuItemStyles={menuItemStyles}>
                  {menu.subMenuItems?.length > 0 ? (
                    <SubMenu
                      label={menu.label}
                      icon={<p className="text-xl">{menu?.icon}</p>}
                      className="z-infinity"
                    >
                      {menu.subMenuItems.map((subMenuItem) => (
                        <Link
                          to={"/en" + subMenuItem.path}
                          key={subMenuItem.key}
                          onClick={() => setToggled(!toggled)}
                        >
                          <MenuItem>{subMenuItem.label}</MenuItem>
                        </Link>
                      ))}
                    </SubMenu>
                  ) : null}
                  {menu.menuItems?.length > 0 &&
                    menu.menuItems.map((a, b) => {
                      return (
                        <Link
                          to={"/en" + a.path}
                          key={a.key}
                          onClick={() => setToggled(!toggled)}
                        >
                          <MenuItem icon={<p className="text-xl">{a?.icon}</p>}>
                            {a.label}
                          </MenuItem>
                        </Link>
                      );
                    })}
                </Menu>
              </div>
            ))}
            <div className="w-full px-2 my-5">
              <ButtonLogout open={!collapsed} action={handleLogout} label="Logout" />
            </div>
          </div>
        </div>
      </Sidebar>
      <main className="h-full bg-secondary w-full relative overflow-hidden">
        <div id="top-header" className="w-full bg-primary h-16 p-3">
          <div className="w-full h-full flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <button
                className={`block opacity-100 cursor-pointer lg:hidden text-2xl text-white duration-500`}
                onClick={() => setToggled(!toggled)}
              >
                <HiOutlineMenu />
              </button>
              <img
                src={process.env.REACT_APP_LOGO_PATH}
                //src="/images/Produk-Kewangan-Main-Logo.png"
                alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
                className="w-24 h-8 object-contain object-center lg:hidden"
              />
            </div>
            <Heading
              size="h4"
              color="white"
              className="text-end font-semibold capitalize"
            >
              <p className="text-white text-sm font-semibold">
                GMT+8: {convertTime(currentDateTime)}
              </p>
              {admin?.admin?.name}
            </Heading>
          </div>
        </div>
        <div
          style={{ minHeight: "calc(100vh - 8rem )" }}
          className="px-3 py-1 lg:px-7 lg:pt-7 lg:pb-12 mb-0"
        >
          <Suspense fallback={<Loading />}>
            <Outlet />
            {children}
          </Suspense>
        </div>
        <Spacing></Spacing>
        <div className="w-full">
          <div className="flex flex-col-reverse lg:flex-row items-center px-5 py-2 justify-between gap-4 w-full">
            <Link to="/" className="flex items-center mb-4 sm:mb-0">
              <span className="self-center text-sm font-semibold whitespace-nowrap text-gray-400">
                {` @${currYear} ${projectTitle} `}
              </span>
            </Link>
            <ul className="flex items-center mb-6 text-sm font-semibold text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <Link to="/about-us" className="mr-4 hover:text-white md:mr-6">
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/risk-disclosure"
                  className="mr-4 hover:text-white md:mr-6"
                >
                  Risk Disclosure
                </Link>
              </li>
              <li>
                <Link
                  to="/return-policy"
                  className="mr-4 hover:text-white md:mr-6 "
                >
                  Return Policy
                </Link>
              </li>
              <li>
                <Link to="/aml-policy" className="mr-4 hover:text-white">
                  AML Policy
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="hover:text-white">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
});

export default DashboardAdminLayout;
